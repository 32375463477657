import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import { Frontmatter } from '../../templates/template-types'

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  LeadershipCard,
  LeadershipCardBody,
  PageTitle,
  TitleBorder
} from '@bscs-dev-team/bscs-design-system-core'

import Layout from '../../components/layout/layout'
import SEO from '../../components/seo'

type Node = {
  id: string
  excerpt: string
  frontmatter: Frontmatter
}

type Edge = {
  node: Node
}

type AllMdx = {
  edges: Edge[]
}

type Data = {
  allMdx: AllMdx
}

type LeadershipPageProps = {
  data: Data
}

const sortPeople = (people: Edge[]): Edge[] => {
  return people.sort((a: Edge, b: Edge) => {
    if (a.node.frontmatter.sortOrder && b.node.frontmatter.sortOrder) {
      if (a.node.frontmatter.sortOrder <= b.node.frontmatter.sortOrder) return -1
      return 1
    }

    if (a.node.frontmatter.sortOrder) return -1
    if (b.node.frontmatter.sortOrder) return 1

    const personALastName: string = a.node.frontmatter.fullName.split(' ')[1]
    const personBLastName: string = b.node.frontmatter.fullName.split(' ')[1]

    if (personALastName >= personBLastName) return 1
    return -1
  })
}

// type either 'Board Member', 'Management', or 'Emeritus Board Member'
const getGroup = (people: Edge[], type: string): Edge[] => {
  //This allows both BOD and Management people to have a sortOrder of 1, 2, etc.
  const typePeople: Edge[] = people.filter(person => person.node.frontmatter.type === type) //Screw performance.  This is so clean and beautiful.
  return sortPeople(typePeople)
}

const LeadershipPage = ({ data }: LeadershipPageProps) => {
  const people: Edge[] = data.allMdx.edges
  const bod: Edge[] = getGroup(people, 'Board Member')
  const emeritus: Edge[] = getGroup(people, 'Emeritus Board Member')
  const management: Edge[] = getGroup(people, 'Management')

  return (
    <Layout>
      <SEO
        title="Meet the BSCS leadership"
        description="Get to know BSCS’s management team and distinguished board of directors."
        canonicalUrl="https://bscs.org/about/leadership/"
      />
      <div>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link className='inline-anchor' to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem>
            About
          </BreadcrumbItem>
          <BreadcrumbItem>
            Leadership
          </BreadcrumbItem>
        </Breadcrumb>
        <PageTitle>Meet the BSCS leadership</PageTitle>
        <TitleBorder />
        <div style={{ marginBottom: '3rem' }}>
          <h2 className="border-bscs-blue-500 border-b-2">Management</h2>
          <div className="flex flex-wrap w-full mt-8">
            { management && management.map((person, index) => {
              return (
                <div
                  className="flex w-full justify-center md:w-1/2 lg:w-1/3 xl:w-1/4"
                  key={`mgmt-${index}`}
                >
                  <LeadershipCard
                    className="flex w-full"
                  >
                    <Img
                      fluid={person.node.frontmatter.image.childImageSharp.fluid}
                      className="w-full rounded-md lg:rounded-t-lg rounded-md lg:rounded-lg shadow-md"
                      alt={person.node.frontmatter.alt}
                      style={{ height: '400px' }}
                    />
                    <LeadershipCardBody>
                      <h3>{person.node.frontmatter.fullName}</h3>
                      <div className="text-bscs-gray-500 text-base italic font-serif">
                        {person.node.frontmatter.position}
                      </div>
                      <p className="mt-3">
                        {person.node.frontmatter.cardDescription}
                        {!person.node.frontmatter.cardDescription && person.node.excerpt}
                      </p>
                      <div className="flex mt-3 justify-end w-full">
                        <Link
                          to={`/about/leadership/${person.node.frontmatter.fullName.replace(/\s/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()}`}
                        >
                          <Button variant="indigo" title="Read More">Read More</Button>
                        </Link>
                      </div>
                    </LeadershipCardBody>
                  </LeadershipCard>
                </div>
              )
            })}
          </div>
        </div>
        <div>
          <h2 className="border-bscs-blue-500 border-b-2">Board of Directors</h2>
          <div className="flex flex-wrap w-full mt-8">
            { bod && bod.map((person, index) => {
              return (
                <div
                  className="flex w-full justify-center md:w-1/2 lg:w-1/3 xl:w-1/4"
                  key={`bod-${index}`}
                >
                  <LeadershipCard
                    className="flex w-full"
                  >
                    <Img
                      className="w-full rounded-md lg:rounded-t-lg rounded-md lg:rounded-lg shadow-md"
                      fluid={person.node.frontmatter.image.childImageSharp.fluid}
                      alt={person.node.frontmatter.alt}
                    />
                    <LeadershipCardBody>
                      <h3>{person.node.frontmatter.fullName}</h3>
                      <div className="text-bscs-gray-500 text-base italic font-serif">
                        {person.node.frontmatter.position}
                      </div>
                      <p className="text-bscs-gray-600 mb-3 mt-3 tracking-wider leading-normal">
                        {person.node.frontmatter.cardDescription}
                        {!person.node.frontmatter.cardDescription && person.node.excerpt}
                      </p>
                      <div className="flex w-full justify-end">
                        <Link
                          to={`/about/leadership/${person.node.frontmatter.fullName.replace(/\s/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()}`}
                        >
                          <Button variant="indigo">Read More</Button>
                        </Link>
                      </div>
                    </LeadershipCardBody>
                  </LeadershipCard>
                </div>
              )
            })}
          </div>
        </div>
        { emeritus.length !== 0 &&
          <div>
            <h2 className="border-b-2 border-bscs-blue-500">Emeritus Board Member</h2>
            <div className="flex flex-wrap mt-8 w-full">
              { emeritus.map((person, index) => {
                return (
                  <div
                    className="flex justify-center md:w-1/2 lg:w-1/3 xl:w-1/4 w-full"
                    key={`emeritus-${index}`}
                  >
                    <LeadershipCard className="flex w-full">
                      <Img
                        fluid={person.node.frontmatter.image.childImageSharp.fluid}
                        className="lg:rounded-lg lg:rounded-t-lg rounded-md shadow-md w-full"
                        alt={person.node.frontmatter.alt}
                      />
                      <LeadershipCardBody>
                        <h3>{person.node.frontmatter.fullName}</h3>
                        <div>
                          <div className="font-serif italic text-base text-bscs-gray-500">
                            {person.node.frontmatter.position}
                          </div>
                          <p className="mt-3">
                            {person.node.frontmatter.cardDescription}
                            {!person.node.frontmatter.cardDescription && person.node.excerpt}
                          </p>
                        </div>
                        <div className="flex justify-end mt-3 w-full">
                          <Link
                            to={`/about/leadership/${person.node.frontmatter.fullName.replace(/\s/g, '-').replace(/[^a-zA-Z0-9-]/g, '').toLowerCase()}`}
                          >
                            <Button variant="indigo" title="Read More">Read More</Button>
                          </Link>
                        </div>
                      </LeadershipCardBody>
                    </LeadershipCard>
                  </div>
                )
              })}
            </div>
          </div>
        }
      </div>
    </Layout>
  )
}

export default LeadershipPage

export const leadershipQuery = graphql`
  query leadershipQuery {
    allMdx(
      filter: {frontmatter: { status: {eq: "Ready"}, page: {eq: "leadership"}}}
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 200)
          frontmatter {
            additionalTags
            cardDescription
            fullName
            type
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            page
            position
            sortOrder
          }
        }
      }
    }
  }
`
